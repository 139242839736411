import {Component, Injector, Input, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {
    GlobalMessageCategory,
    GlobalMessageDto,
    GlobalMessagesServiceProxy,
    GlobalMessageSettingsServiceProxy
} from "@shared/service-proxies/service-proxies";
import {AppComponentBase} from "@shared/common/app-component-base";
import {AlertComponent} from "ngx-bootstrap/alert/alert.component";
import {CommunicatonService} from "@app/shared/services/communication.service";
import {Carousel} from "primeng/carousel";

@Component({
    templateUrl: "./messagebar.component.html",
    selector: "message-bar",
    encapsulation: ViewEncapsulation.None,
})
export class MessageBarComponent extends AppComponentBase implements OnInit {
    @Input() dismissible: boolean = true;
    @Input() numVisible: any = 1;
    @Input() showGridValue: boolean = false;
    responsiveOptions;
    styleMessages: boolean;
    autoPlay = 10000;
    @ViewChild('carousel') carousel: Carousel;
    classOverlay: any;
    carouselFlag: boolean = true;

    globalMessages: GlobalMessageDto[] = [];
    alerts: any[] = [];

    constructor(
        injector: Injector,
        private _customGlobalMessagesService: GlobalMessagesServiceProxy,
        private _communicationService: CommunicatonService,
        private _globalMessageSettings: GlobalMessageSettingsServiceProxy
    ) {
        super(injector);
    }

    ngOnInit() {
        this.getGlobalMessages();
        this.loadHostSettings();
        this.loadTimer();
    }

    getGlobalMessages() {
        this._customGlobalMessagesService.getAllValid().subscribe((result) => {
            this.globalMessages = result;
            this.addAlerts();
        });
    }

    truncateHTML(text: string, count: number): string {

        let charlimit = count;
        if (!text || text.length <= charlimit) {
            return text;
        }


        let without_html = text.replace(/<(?:.|\n)*?>/gm, '');
        let shortened = without_html.substring(0, charlimit) + "...";
        return shortened;
    }

    addAlerts() {
        if (this.globalMessages) {
            this.globalMessages.forEach((element) => {
                this.alerts.push({
                    type: this.alertType(element),
                    msg: element.message,
                    dismissible: this.dismissible,
                    title: element.title,
                });
            });
        }
    }

    alertType(element: GlobalMessageDto): string {
        switch (element.category) {
            case GlobalMessageCategory.Danger:
                return "danger";
            case GlobalMessageCategory.Info:
                return "info";
            case GlobalMessageCategory.Primary:
                return "primary";
            case GlobalMessageCategory.Secondary:
                return "secondary";
            case GlobalMessageCategory.Success:
                return "success";
            case GlobalMessageCategory.Warning:
                return "warning";

            default:
                break;
        }

        return "";
    }

    onClosed(dismissedAlert: AlertComponent): void {
        this.alerts = this.alerts.filter((alert) => alert !== dismissedAlert);
    }

    setBackground(product: any) {
        return `alert-${product.type}`;
    }

    loadHostSettings(): void {
        const self = this;
        self._globalMessageSettings.getHostMessageType()
            .subscribe(setting => {
                this._communicationService.setMessageType(setting.messagesType);
                this.getMessageTypeValue();
            });
    }

    getMessageTypeValue() {
        this._communicationService.getMessageType().subscribe((res) => {
            this.styleMessages = res;
            if (res) {
                this.loadTimer();
            }
        });
    }

    stopCarousel() {
        this.carousel.stopAutoplay()
    }

    startCarousel() {
        this.loadTimer();
    }

    loadTimer() {
        const self = this;
        self._globalMessageSettings.getHostMessageTimer()
            .subscribe(setting => {
                this._communicationService.setMessageTimer(setting.messagesTimer);
                this.getTimer();
            });
    }

    getTimer() {

        if (this.carousel !== undefined) {

            this._communicationService.getMessageTimer().subscribe((res) => {

                if (res < 1) {

                    this.carousel.stopAutoplay()

                } else {

                    this.carousel.stopAutoplay()

                    this.carousel.autoplayInterval = res;

                    this.carousel.startAutoplay();

                }

            });

        }
    }

    closeCarousel() {
        this.carouselFlag = false;
    }
}
