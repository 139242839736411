import { Injector, OnInit } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppUrlService } from '@shared/common/nav/app-url.service';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppUiCustomizationService } from '@shared/common/ui/app-ui-customization.service';
import { SettingsServiceProxy, UiCustomizationSettingsDto } from '@shared/service-proxies/service-proxies';
import { FileUpload } from 'primeng/fileupload';
import { PrimengTableHelper } from 'shared/helpers/PrimengTableHelper';
import {
    AbpMultiTenancyService,
    FeatureCheckerService,
    LocalizationService,
    MessageService,
    NotifyService,
    PermissionCheckerService,
    SettingService
} from "abp-ng2-module";
import moment from "moment-timezone";
import { SelectItem } from "primeng/api";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { HttpClient } from "@angular/common/http";
import { HubConnection } from "@microsoft/signalr";
import { SignalRHelper } from "@shared/helpers/SignalRHelper";

export abstract class AppComponentBase {

    static messagesHub: HubConnection;
    static isInitializing: boolean = false;
    localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;
    localization: LocalizationService;
    permission: PermissionCheckerService;
    feature: FeatureCheckerService;
    notify: NotifyService;
    setting: SettingService;
    message: MessageService;
    multiTenancy: AbpMultiTenancyService;
    appSession: AppSessionService;
    primengTableHelper: PrimengTableHelper;
    ui: AppUiCustomizationService;
    appUrlService: AppUrlService;
    httpClient: HttpClient;
    sanitizer: DomSanitizer;
    currentPageReport: string;
    emptyPageReport: string;
    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;
    settingServiceProxy: SettingsServiceProxy;

    constructor(injector: Injector) {
        this.localization = injector.get(LocalizationService);
        this.permission = injector.get(PermissionCheckerService);
        this.feature = injector.get(FeatureCheckerService);
        this.notify = injector.get(NotifyService);
        this.setting = injector.get(SettingService);
        this.message = injector.get(MessageService);
        this.multiTenancy = injector.get(AbpMultiTenancyService);
        this.appSession = injector.get(AppSessionService);
        this.ui = injector.get(AppUiCustomizationService);
        this.appUrlService = injector.get(AppUrlService);
        this.httpClient = injector.get(HttpClient);
        this.sanitizer = injector.get(DomSanitizer);
        this.settingServiceProxy = injector.get(SettingsServiceProxy);
        this.primengTableHelper = new PrimengTableHelper();

        if (!!abp.session.userId && !AppComponentBase.messagesHub && !AppComponentBase.isInitializing) {
            AppComponentBase.isInitializing = true;
            SignalRHelper.initSignalR(() => {
                abp.signalr.startConnection('/signalr-messagesHub', connection => {
                    AppComponentBase.messagesHub = connection;
                    AppComponentBase.isInitializing = false;
                });
            });
        }

        this.currentPageReport = this.l('ShowingRecords');
        this.emptyPageReport = this.l('TableNoEntriesFound');
    }

    getEnumAsSelectItems<T>(type: T, withZero: boolean = true): SelectItem[] {
        return Object.keys(type).filter(f => !isNaN(Number(f)) && (withZero || Number(f) !== 0)).map<SelectItem>(m => {
            return {
                disabled: false,
                label: this.l(type[m]),
                value: Number(m)
            };
        });
    }

    get currentTheme(): UiCustomizationSettingsDto {
        return this.appSession.theme;
    }

    IsDebugMode(): boolean {
        return abp.custom.IsDebugMode;
    }

    flattenDeep(array) {
        return array.reduce((acc, val) =>
            Array.isArray(val) ?
                acc.concat(this.flattenDeep(val)) :
                acc.concat(val),
            []);
    }

    l(key: string, ...args: any[]): string {
        args.unshift(key);
        args.unshift(this.localizationSourceName);
        return this.ls.apply(this, args);
    }

    ls(sourcename: string, key: string, ...args: any[]): string {
        let localizedText = this.localization.localize(key, sourcename);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, this.flattenDeep(args));
    }

    isGranted(permissionName: string): boolean {
        return this.permission.isGranted(permissionName);
    }

    isGrantedAny(...permissions: string[]): boolean {
        if (!permissions) {
            return false;
        }

        for (const permission of permissions) {
            if (this.isGranted(permission)) {
                return true;
            }
        }

        return false;
    }

    s(key: string): string {
        return abp.setting.get(key);
    }

    appRootUrl(): string {
        return this.appUrlService.appRootUrl;
    }

    appBaseHref(): string {
        return AppConsts.appBaseHref;
    }

    onUploadError(event, fileUpload: FileUpload) {
        if (fileUpload) {
            fileUpload.clear();
            fileUpload.clearIEInput();
            fileUpload.clearInputElement();
        }
        abp.message.error(event.error.error.result);
    }

    attachmentMimeTypes(): string[] {
        return abp.custom.attachmentMimeTypes;
    }

    public getMoment(date?: Date): moment.Moment {
        if (!date)
            return null;
        return moment(date);
    }
    public getMomentUtc(date?: Date): moment.Moment {
        if (!date)
            return null;
        return moment.utc(date);
    }
    tenantTypeInfo(): any {
        return abp.custom.tenantTypeInfo;
    }

}
