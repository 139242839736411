import { AbpHttpInterceptor } from "abp-ng2-module";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';

@NgModule({
    providers: [
        ApiServiceProxies.AnnexEquivalenceValuesServiceProxy,
        ApiServiceProxies.SynchronizationServiceProxy,
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.NotePropertyTaxAssessmentsServiceProxy,
        ApiServiceProxies.NotePropertyTaxsServiceProxy,
        ApiServiceProxies.BuildingsServiceProxy,
        ApiServiceProxies.NoteRateableValuesServiceProxy,
        ApiServiceProxies.LandsServiceProxy,
        ApiServiceProxies.EconomicUnitsServiceProxy,
        ApiServiceProxies.OutsideFacilitiesServiceProxy,
        ApiServiceProxies.CompaniesServiceProxy,
        ApiServiceProxies.RenteesServiceProxy,
        ApiServiceProxies.MunicipalitiesServiceProxy,
        ApiServiceProxies.LookupServiceProxy,
        ApiServiceProxies.CollectionRatesServiceProxy,
        ApiServiceProxies.OperatingEquipmentsServiceProxy,
        ApiServiceProxies.Annex27sServiceProxy,
        ApiServiceProxies.Annex28sServiceProxy,
        ApiServiceProxies.Annex30sServiceProxy,
        ApiServiceProxies.Annex31sServiceProxy,
        ApiServiceProxies.Annex32sServiceProxy,
        ApiServiceProxies.Annex36sServiceProxy,
        ApiServiceProxies.Annex37sServiceProxy,
        ApiServiceProxies.Annex40sServiceProxy,
        ApiServiceProxies.Annex41sServiceProxy,
        ApiServiceProxies.Annex39IIsServiceProxy,
        ApiServiceProxies.Annex43sServiceProxy,
        ApiServiceProxies.Annex42sServiceProxy,
        ApiServiceProxies.Annex38sServiceProxy,
        ApiServiceProxies.AnnexPara256sServiceProxy,
        ApiServiceProxies.Annex38sServiceProxy,
        ApiServiceProxies.CalculationsServiceProxy,
        ApiServiceProxies.ChangeLogServiceProxy,
        ApiServiceProxies.Annex39IServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.MailingServiceProxy,
        ApiServiceProxies.AnnexBuildingCostsServiceProxy,
        ApiServiceProxies.AnnexAssessmentRatesServiceProxy,
        ApiServiceProxies.DatabaseManagementServiceProxy,
        ApiServiceProxies.Annex29sServiceProxy,
        ApiServiceProxies.Annex33sServiceProxy,
        ApiServiceProxies.AzureMapsServiceProxy,
        ApiServiceProxies.MigrationServiceProxy,
        ApiServiceProxies.AdminExportServiceProxy,
        ApiServiceProxies.GlobalMessagesServiceProxy,
        ApiServiceProxies.KPMGAppAttachmentsAppServiceProxy,
        ApiServiceProxies.TableConfigServiceProxy,
        ApiServiceProxies.UserEconomicUnitPermissionsServiceProxy,
        ApiServiceProxies.HostBackgroundJobInfosServiceProxy,
        ApiServiceProxies.BackgroundWorkerServiceProxy,
        ApiServiceProxies.GlobalMessageSettingsServiceProxy,
        ApiServiceProxies.InboxServiceProxy,
        ApiServiceProxies.AnnexResidentialAreaHamburgServiceProxy,
        ApiServiceProxies.RequestMunicipalitiesServiceProxy,
        ApiServiceProxies.RequestCollectionRatesServiceProxy,
        ApiServiceProxies.EricServiceProxy,
        ApiServiceProxies.AggregationPropertyTaxServiceProxy,
        ApiServiceProxies.IndividualDataServiceProxy,
        ApiServiceProxies.InvoiceReportsServiceProxy,
        ApiServiceProxies.Backend3PartyLicenseServiceProxy,
        ApiServiceProxies.DeclarationsServiceProxy,
        ApiServiceProxies.AnnexPlausibilityCheckRenovatedAreasServiceProxy,
        ApiServiceProxies.AnnexPlausibilityCheckGrossFloorAreasServiceProxy,
        ApiServiceProxies.DeclarationCommentServiceProxy,
        ApiServiceProxies.DeclarationFormServiceProxy,
        ApiServiceProxies.SubmissionFieldValuesServiceProxy,
        ApiServiceProxies.SGVRequestServiceProxy,
        ApiServiceProxies.KnowledgeBoxServiceProxy,
        ApiServiceProxies.KnowledgeArticleServiceProxy,
        ApiServiceProxies.SubmissionOverviewServiceProxy,
        ApiServiceProxies.TaxExemptionsServiceProxy,
        ApiServiceProxies.BuildingTypesServiceProxy,
        ApiServiceProxies.ImportTemplateServiceProxy,
        ApiServiceProxies.MassDeclartionsSubmitServiceProxy,
        ApiServiceProxies.MassDeclartionsCreateServiceProxy,
        ApiServiceProxies.PdfTemplateServiceProxy,
        ApiServiceProxies.DistrictsServiceProxy,
        ApiServiceProxies.RequestDistrictServiceProxy,
        ApiServiceProxies.CreatePdfFromTemplateServiceProxy,
        ApiServiceProxies.ReportingDutyServiceProxy,
        ApiServiceProxies.TenantBackgroundJobInfosServiceProxy,
        ApiServiceProxies.DueDateServiceProxy,
        ApiServiceProxies.RequestDueDateServiceProxy,
        ApiServiceProxies.NoteDataCompareServiceProxy,
        ApiServiceProxies.TaxExemptionDefinitionServiceProxy,
        ApiServiceProxies.CompareMappingReferenceServiceProxy,
        ApiServiceProxies.CalculationReportServiceProxy,
        ApiServiceProxies.LinkedObjectsServiceProxy,
        ApiServiceProxies.TaxOfficesServiceProxy,
        ApiServiceProxies.HolidaysServiceProxy,
        ApiServiceProxies.LegalRemedyServiceProxy,
        ApiServiceProxies.DeadLineCalculationServiceProxy,
        ApiServiceProxies.MassLegalRemedyCreateServiceProxy,
        ApiServiceProxies.MassLegalRemedySubmitServiceProxy,
        ApiServiceProxies.MassDeclarationsValidateServiceProxy,
        ApiServiceProxies.MassDeclarationsChangeStatusServiceProxy,
        ApiServiceProxies.MassXmlCreateServiceProxy,
        ApiServiceProxies.HostSynchronizationServiceProxy,
        ApiServiceProxies.ObjectionTemplatesServiceProxy,
        ApiServiceProxies.LandStateDevelopmentServiceProxy,
        ApiServiceProxies.DeclLandStateDevelopmentServiceProxy,
        ApiServiceProxies.MunicipalityExtraDataServiceProxy,
        ApiServiceProxies.ReportingDutyDetailsServiceProxy,
        ApiServiceProxies.StorageServiceServiceProxy,
        ApiServiceProxies.OtherMessagesServiceProxy,
        ApiServiceProxies.OtherMessagesCompareServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.PaymentReceiptsServiceProxy,
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
    ]
})
export class ServiceProxyModule {
}
