import {
    Component,
    ContentChildren,
    EventEmitter,
    Injector,
    Input,
    OnChanges, OnDestroy,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    TemplateRef,
    ViewChild, ViewChildren,
} from '@angular/core';
import {
    _definitions_TableConfigurationType,
    ColumnConfigDto,
    GetColumnsConfigInput,
    GetColumnsToUpdateInput,
    TableConfigServiceProxy,
    TableFieldType,
} from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { FilterMetadata, MenuItem, SelectItem, } from 'primeng/api';
import { TableUtilitiesService } from '@shared/utils/table-utils-service';
import { FieldTypeTemplateDirective } from '@shared/utils/field-type-template.directive';
import {
    Table,
    TableEditCancelEvent,
    TableEditCompleteEvent,
    TableEditInitEvent,
    TableLazyLoadEvent
} from 'primeng/table';
import { KpmgTableCaptionExtraDirective } from '@shared/utils/kpmg-table-caption-extra.directive';
import { ModelUploadHelper } from '@shared/helpers/ModelUploadHelper';
import { AppConsts } from '@shared/AppConsts';
import * as moment from "moment-timezone";
import { Subject } from "rxjs";
import { SlideMenu } from 'primeng/slidemenu';
import { forEach } from 'lodash';

@Component({
    selector: 'kpmg-table',
    templateUrl: './kpmg-table.component.html',
    styleUrls: ['./kpmg-table.component.css'],
})
export class KpmgTableComponent extends AppComponentBase
    implements OnInit, OnChanges, OnDestroy {
    @ViewChild('dt') table: Table;
    @Input() globalSearch = true;
    @Input() lazy: boolean = true;
    @Input() value: any;
    @Input() dataKey = 'id';
    @Input() autoLayout = true;
    @Input() stateStorage: 'session' | 'local' = 'local';
    // @Input() stateKey: string;
    @Output() onLazyLoad: EventEmitter<TableLazyLoadEvent> = new EventEmitter();
    @Input() totalRecords: number = 0;
    @Input() paginator = true;
    @Input() rows = 10;
    @Input() rowHover = true;
    @Input() showCurrentPageReport = true;
    @Input() rowsPerPageOptions = [10, 25, 50, 100, 250, 500];
    @Input() menuItems: MenuItem[][] = [];
    @Input() customMenuItems: MenuItem[] = [];
    @Input() tableConfigType: _definitions_TableConfigurationType;
    @Input() overrideViewMenuSize: number;
    @Input() viewTableConfigButton = true;
    @Input() viewActionButton = true;
    @Input() preDefinedFilters: { [s: string]: FilterMetadata; } = null;
    @Input() styleClass: string;
    @Input() reorderableColumns: boolean = true;
    @Input() selectableFilter: boolean = false;
    @Input() menuWidth: number = 175;

    @Output() editableValue: EventEmitter<any> = new EventEmitter();
    @Output() onResetFilters: EventEmitter<void> = new EventEmitter();

    tableConfigTypes = _definitions_TableConfigurationType;

    loading = true;
    public columns: ColumnConfigDto[];
    enumerations: any;
    actionButtonSize: number[] = [];
    customActionButtonSize: number[] = [];
    visibleSidebar = false;
    dateFilters: Date[][] = [];
    dateFilter: Date[] = [];
    currentPageReport: string = this.l('ShowingRecords');
    emptyPageReport: string = this.l('TableNoEntriesFound');
    booleanOptions: SelectItem[];
    booleanTristateOptions: SelectItem[];

    selectedItems: any;

    updateUrl = AppConsts.remoteServiceBaseUrl + "/TableConfig/Update";
    updateConfigTableUrl = AppConsts.remoteServiceBaseUrl + "/TableConfig/UpdateConfigTable";
    updateOrderingConfigTableUrl = AppConsts.remoteServiceBaseUrl + "/TableConfig/UpdateOrderingConfigTable";
    @ContentChildren(FieldTypeTemplateDirective)
    private fieldTypeTemplateDirectives: QueryList<FieldTypeTemplateDirective>;
    @ContentChildren(KpmgTableCaptionExtraDirective)
    private kpmgTableCaptionExtraDirectives: QueryList<KpmgTableCaptionExtraDirective>;
    globalSearchInput: string;
    filterTextChanged: Subject<any> = new Subject<any>();
    prevMenu!: SlideMenu | undefined;
    showMenu: boolean;

    constructor(
        injector: Injector,
        private tableConfigService: TableConfigServiceProxy,
        protected tableUtilsService: TableUtilitiesService,
        private _modelUploadHelper: ModelUploadHelper
    ) {
        super(injector);
    }

    ngOnDestroy(): void {

    }

    debounceInput(value: any, field: any) {
        if (!this.filterTextChanged.observed) {
            this.filterTextChanged.subscribe(query => {
                if (query.field === '') {
                    this.table.filterGlobal(query.value, '');
                } else {
                    this.table.filter(query.value, query.field, '');
                }
            });
        }
        this.filterTextChanged.next({ value, field });
    }

    globalButtonSearch() {
        if (this.globalSearchInput || this.globalSearchInput == '') {
            this.debounceInput(this.globalSearchInput, '');
        }
    }

    toUTC(momentDate?: moment.Moment) {
        if (!momentDate)
            return null;
        let date = momentDate.toDate();
        return new Date(Date.UTC(date.getFullYear()
            , date.getMonth()
            , date.getDate()
            , 0, 0, 0));
    }

    hideSlideMenu(): void {
        setTimeout(() => {
            const result = document.querySelectorAll('.p-slidemenu-overlay');
            if (result.length > 0) {
                const divOverlay = result[result.length - 1];
                divOverlay.setAttribute("style", "display: none");
            }
        }, 0);
    }

    onShowMenu(menu: SlideMenu, event: any) {
        if (menu) {
            //console.info("MENU SHOW: ", menu.id);
            menu.toggle(event);
            this.setMenuWidth(menu);
            if (this.prevMenu && this.prevMenu.id != menu.id) {
                //console.info("PREV MENU SHOW: ", this.prevMenu.id);
                this.prevMenu.hide();
            }
            this.prevMenu = menu;
            this.showMenu = true;
        }
    }

    onOverlayClick(event: MouseEvent) {
        //console.info("OVERLAY CLICK: ", this.showMenu);
        if (this.prevMenu && this.prevMenu.visible && !this.showMenu) {
            //console.info("PREV MENU HIDE: ", this.prevMenu.id);
            this.prevMenu.hide();
            this.prevMenu = undefined;
        }
        this.showMenu = false;
    }


    hasExtraCaption() {
        return this.kpmgTableCaptionExtraDirectives.length > 0;
    }

    getExtraCaption(): TemplateRef<any> {
        return this.kpmgTableCaptionExtraDirectives.first.templateRef;
    }

    hasTemplate(field: string): boolean {
        let template = this.fieldTypeTemplateDirectives.find(
            (f) => f.fieldType === field
        );
        return !!template;
    }

    getTemplate(field: string): TemplateRef<any> {
        let template = this.fieldTypeTemplateDirectives.find(
            (f) => f.fieldType === field
        );
        return template.templateRef;
    }

    ngOnInit() {
        this.tableConfigService
            .getTableConfig(this.tableConfigType)
            .subscribe((s) => {
                this.columns = s.columns;
                this.enumerations = s.enumLabels;
                // init date filters
                // now that columns are loaded we can load table data
                this.fillPreDefinedFilters();
                this.columns
                    .filter(
                        (f) =>
                            f.isFilterEnabled &&
                            (f.fieldType === TableFieldType.Date ||
                                f.fieldType === TableFieldType.Datetime ||
                                f.fieldType === TableFieldType.DatePicker || f.fieldType === TableFieldType.Year || f.fieldType === TableFieldType.DateUTCRange)
                    )
                    .map((s) => s.field)
                    .forEach((item) => {
                        if (!this.table.filters[item]) {
                            return;
                        }
                        this.dateFilters[item] = [
                            new Date((<FilterMetadata>this.table.filters[item]).value[0]),
                            new Date((<FilterMetadata>this.table.filters[item]).value[1]),
                        ];
                        this.dateFilter[item] = [
                            new Date((<FilterMetadata>this.table.filters[item]).value)
                        ];
                    });

                if (this.lazy)
                    this.onLazyLoad.emit(this.table.createLazyLoadMetadata());
                else
                    this.loading = false;
            });

        this.booleanOptions = [
            { label: this.l('Yes'), value: true },
            { label: this.l('No'), value: false }
        ];
        this.booleanTristateOptions = [
            { label: this.l('NotSet'), value: null },
            { label: this.l('Yes'), value: true },
            { label: this.l('No'), value: false }
        ];
    }

    ngOnChanges(simpleChange: SimpleChanges) {
        if (simpleChange.value && !simpleChange.value.firstChange) {
            this.loading = false;
        } else {
            if (!simpleChange.value) {
                this.loading = false;
            }
        }

        if (simpleChange.menuItems && !simpleChange.menuItems.firstChange) {
            if (this.menuItems) {
                this.actionButtonSize = [];
                let sizeEachOption = 38.5;
                if (this.overrideViewMenuSize) {
                    sizeEachOption = this.overrideViewMenuSize;
                }
                this.menuItems.forEach((item, index) => {
                    this.actionButtonSize[index] =
                        sizeEachOption * (this.menuItems[index].length + 1.16);
                });
            }
        }
        if (simpleChange.customMenuItems && !simpleChange.customMenuItems.firstChange) {

            if (this.customMenuItems) {
                this.customActionButtonSize = [];
                let sizeEachOption = 38.5;
                if (this.overrideViewMenuSize) {
                    sizeEachOption = this.overrideViewMenuSize;
                }
                this.customMenuItems.forEach((item, index) => {
                    this.customActionButtonSize[index] =
                        sizeEachOption * (this.customMenuItems.length + 1.09);
                });
            }
        }
    }

    saveTableConfig() {
        let input = new GetColumnsConfigInput();
        input.tableConfigurationType = this.tableConfigType;
        input.hiddenColumns = this.columns
            .filter((f) => f.isHidden)
            .map((m) => m.field);
        this._modelUploadHelper.sendModel(this.updateUrl, input).subscribe(() => {
            this.visibleSidebar = false;
            this.notify.success(this.l('SuccessfullySaved'));
        });
    }

    resetFilters() {
        this.onResetFilters.emit();
        this.table.filters = {};
        this.dateFilters = [];
        this.table.reset();
        this.table.clearState();
    }

    fillPreDefinedFilters() {
        if (this.preDefinedFilters) {
            this.table.filters = this.preDefinedFilters;
        }
    }

    truncateString(text: any, length: number | undefined): string {
        if (!length) {
            length = 25;
        }

        return abp.utils.truncateStringWithPostfix(text, length);
    }

    setWidth(width: any) {
        return `${width}px`;
    }

    setMenuWidth(menu: SlideMenu) {
        if (menu.style == undefined)
            menu.style = { width: this.setWidth(this.menuWidth + 3) };
    }

    updateTableConfiguration(event: any) {
        let input = new GetColumnsToUpdateInput();
        input.field = event.element.id;
        input.tableConfigurationType = this.tableConfigType;
        input.userId = abp.session.userId;
        input.width = event.element.clientWidth;

        this._modelUploadHelper.sendModel(this.updateConfigTableUrl, input).subscribe(() => {
        });
        // this.tableConfigService.updateConfigTable(input).subscribe(() => {
        //     // console.log('success')
        // });
    }

    updateOrderConfig(event: any) {
        let input = new GetColumnsToUpdateInput();
        input.columns = event.columns;
        input.userId = abp.session.userId;
        input.tableConfigurationType = this.tableConfigType;
        this._modelUploadHelper.sendModel(this.updateOrderingConfigTableUrl, input).subscribe(() => {
        });
        // this.tableConfigService.updateOrderingConfigTable(input).subscribe(() => {
        //     // console.log('success')
        // });
    }

    reloadPage(): void {
        this.loading = true;
        this.onLazyLoad.emit(this.table.createLazyLoadMetadata());
    }
    updateField(column: any, data: any) {
        this.editableValue.emit({ column, data });
    }

    deleteTableConfig() {
        this.tableConfigService.deleteConfigTable(this.tableConfigType).subscribe(res => {
            window.location.reload();
        });
    }

    clearFilter(field: any) {
        this.table.filters[field] = [];
        this.table.filter(undefined, field, undefined);
    }

    selectAll() {
        this.selectedItems = this.value;
    }

    deselectAll() {
        this.selectedItems = [];
    }

    getPageReportTemplate(): string {
        if (this.selectedItems == undefined) {
            return this.totalRecords == 0 ? this.emptyPageReport : this.currentPageReport;
        }
        if (this.selectedItems.length > 0) {
            const countSelectedItems = this.selectedItems.length;
            const selectedPageReport = this.l("ShowingRecords") + this.l("ShowingSelectedRecords", countSelectedItems);
            this.currentPageReport = selectedPageReport;
        } else {
            this.currentPageReport = this.l("ShowingRecords");
        }
        return this.totalRecords == 0 ? this.emptyPageReport : this.currentPageReport;
    }
}
